<template>
  <b-modal id="modal-user" hide-footer header-class="align-items-center">
    <template #modal-header="{ close }">
      <h5 class="mb-0">
        {{ form.id ? "Actualizar usuario" : "Crear usuario" }}
      </h5>
      <b-button
        variant="text"
        @click="close()"
        class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
        style="min-height: 24px"
      >
        <i
          class="ri-close-line hp-text-color-dark-0 lh-1"
          style="font-size: 24px"
        ></i>
      </b-button>
    </template>

    <b-form class="mb-8">
      <b-form-group
        label="Nombre del usuario :"
        label-for="userName"
        class="mb-16"
      >
        <b-form-input
          v-model="form.first_name"
          id="userName"
          :class="{ 'is-invalid': $v.form.first_name.$error }"
          type="text"
          placeholder="Nombre del usuario"
        ></b-form-input>
        <div v-if="$v.form.first_name.$error" class="invalid-feedback">
          <span v-if="!$v.form.first_name.required"
            >Nombre del usuario es requerido.</span
          >
        </div>
      </b-form-group>

      <b-form-group
        label="Apellido del usuario :"
        label-for="userLastName"
        class="mb-16"
      >
        <b-form-input
          v-model="form.last_name"
          id="userLastName"
          :class="{ 'is-invalid': $v.form.last_name.$error }"
          type="text"
          placeholder="Apellido del usuario"
        ></b-form-input>
        <div v-if="$v.form.last_name.$error" class="invalid-feedback">
          <span v-if="!$v.form.last_name.required"
            >Apellido del usuario es requerido.</span
          >
        </div>
      </b-form-group>

      <b-form-group
        label="Correo electrónico :"
        label-for="loginEmail"
        class="mb-16"
      >
        <b-form-input
          v-model="form.email"
          id="loginEmail"
          :class="{ 'is-invalid': $v.form.email.$error }"
          type="text"
          placeholder="Correo electrónico"
        ></b-form-input>
        <div v-if="$v.form.email.$error" class="invalid-feedback">
          <span v-if="!$v.form.email.required"
            >Correo electrónico es requerido.</span
          >
          <span v-if="!$v.form.email.email"
            >Ingresa un correo electrónico valido.</span
          >
        </div>
      </b-form-group>

      <b-form-group label="RUT del usuario :" label-for="userRut" class="mb-16">
        <b-form-input
          v-model="form.rut"
          id="userRut"
          type="text"
          placeholder="RUT del usuario"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Rol del usuario :" label-for="rolUser" class="mb-16">
        <b-form-select
          v-model="form.rol"
          :options="wrappedRoles"
          id="rolUser"
          class="mb-1"
          :class="{ 'is-invalid': $v.form.rol.$error }"
          value-field="id"
          text-field="name"
        ></b-form-select>
        <div v-if="$v.form.rol.$error" class="invalid-feedback">
          <span v-if="!$v.form.rol.required">Rol es requerido.</span>
        </div>
      </b-form-group>
    </b-form>

    <div class="d-flex justify-content-end flex-wrap mt-32">
      <b-button variant="outline-info-3" @click="closeModal"> Cerrar </b-button>
      <b-button
        type="submit"
        class="ml-16"
        variant="outline-primary"
        @click="handleSubmit"
      >
        <b-spinner v-if="loading" small type="grow" class="mr-8"></b-spinner>
        {{ form.id ? "Actualizar usuario" : "Crear usuario" }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
const { required, email, minLength } = require("vuelidate/lib/validators");
import { ROLES } from "@/constants/roles";
export default {
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      form: {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
        rut: "",
        rol: null,
      },
    };
  },
  validations: {
    form: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
        minLength: minLength(6),
      },
      rut: {},
      rol: {
        required,
      },
    },
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    ...mapActions({
      getRoles: "user/getRoles",
      updateOrCreateUser: "user/updateOrCreateUser",
    }),
    show(user) {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      if (user) {
        this.form = {
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          rut: user.rut,
          rol: user.rol ? user.rol : null,
        };
      }
      this.$bvModal.show("modal-user");
    },
    async handleSubmit() {
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.loading = true;
        const { id, ...rest } = this.form;
        let response;
        if (id) {
          response = await this.updateOrCreateUser({
            id,
            ...rest,
          });
        } else {
          response = await this.updateOrCreateUser({
            ...rest,
            password: this.form.rut,
          });
        }
        this.loading = false;
        if (response.status == 400) {
          return this.$bvToast.toast(`El correo electrónico ya existe`, {
            title: `Error`,
            variant: "danger",
            solid: false,
          });
        }
        if (response.status == 201 || response.status == 200) {
          this.$bvToast.toast(
            `Usuario ${id ? "actualizado" : "creado"} correctamente`,
            {
              title: `Excelente`,
              variant: "primary",
              solid: false,
            }
          );
          this.closeModal();
          this.$emit("success");
        }
      }
    },
    closeModal() {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.$bvModal.hide("modal-user");
      this.form = {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
        rut: "",
        rol: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      roles: "user/roles",
    }),
    wrappedRoles() {
      return [{ id: null, name: "Selecciona un Rol" }, ...this.roles].filter(
        (item) => {
          return item.id !== ROLES.superadmin;
        }
      );
    },
  },
};
</script>

<style></style>
