<template>
  <b-row>
    <b-col cols="12" class="mb-32">
      <b-row align-h="between" class="mt-n32">
        <b-col class="hp-flex-none w-auto mt-32">
          <breadcrumb activeTitle="Usuarios" />
        </b-col>
        <b-col class="hp-flex-none w-auto mt-24">
          <b-row>
            <b-col class="hp-flex-none w-auto pr-0">
              <b-button
                @click="$refs.editUser.show(null)"
                variant="primary"
                class="btn-ghost"
                >Agregar Usuario</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" class="mb-32">
      <h2 class="mb-0">Usuarios</h2>
      <h5 class="mb-0 text-black-80 hp-text-color-dark-30">
        Listado de todos los usuarios
      </h5>
    </b-col>

    <b-col cols="12" class="mb-32">
      <b-row>
        <b-col cols="12" xl="4" md="4" sm="12">
          <b-form-input
            v-model="filters.first_name"
            type="text"
            @input="getUsersData()"
            placeholder="Buscar usuarios por Nombre"
          ></b-form-input>
        </b-col>
        <b-col cols="12" xl="4" md="4" sm="12">
          <b-form-input
            v-model="filters.last_name"
            type="text"
            @input="getUsersData()"
            placeholder="Buscar usuarios por Apellido"
          ></b-form-input>
        </b-col>
        <b-col cols="12" xl="4" md="4" sm="12">
          <b-form-input
            v-model="filters.rut"
            type="text"
            @input="getUsersData()"
            placeholder="Buscar usuarios por RUT"
          ></b-form-input>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12">
      <b-row v-if="wrappedUsers.length">
        <b-col
          cols="12"
          md="6"
          xl="4"
          :key="index"
          v-for="(item, index) in wrappedUsers"
          class="mb-32"
        >
          <card-basic
            :title="item.full_name"
            :desc="item.email"
            :img="item.image"
          >
            <template #actions>
              <div class="" v-if="currentUser && item.id !== currentUser.id">
                <b-button
                  size="sm"
                  v-b-tooltip.hover
                  title="Actualizar usuario"
                  variant="warning"
                  class="mt-16 mx-3 btn-ghost"
                  @click="$refs.editUser.show(item)"
                >
                  <i class="iconly-Curved-Edit"></i>
                </b-button>
                <b-button
                  size="sm"
                  v-b-tooltip.hover
                  title="Eliminar usuario"
                  variant="danger"
                  @click="deleteUser(item)"
                  class="mt-16 btn-ghost"
                >
                  <i class="iconly-Curved-Delete"></i>
                </b-button>
              </div>
            </template>
          </card-basic>
        </b-col>
      </b-row>
      <h5 v-else class="text-center mt-16">Sin usuarios actualmente</h5>
    </b-col>

    <b-col cols="12">
      <div class="d-flex justify-content-center mt-5">
        <b-pagination
          v-model="filters.page"
          :per-page="12"
          :total-rows="usersPaginated.items"
        ></b-pagination>
      </div>
    </b-col>

    <update-or-create-user
      ref="editUser"
      @success="getUsersData"
    ></update-or-create-user>
  </b-row>
</template>

<script>
import Breadcrumb from "@/layouts/components/content/breadcrumb/Breadcrumb.vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import CardBasic from "@/view/components/common/cards/CardBasic.vue";
import { mapActions, mapGetters } from "vuex";
import UpdateOrCreateUser from "./updateOrCreateUser.vue";

export default {
  components: {
    Breadcrumb,
    PageTitle,
    CardBasic,
    UpdateOrCreateUser,
  },
  data() {
    return {
      filters: {
        first_name: null,
        last_name: null,
        rut: null,
        page: 1,
        size: 12,
      },
      debounce: null,
      debounceTime: 300,
    };
  },
  mounted() {
    this.getUsersData();
  },
  methods: {
    ...mapActions({
      getUsers: "user/getUsers",
      getUsersPaginated: "user/getUsersPaginated",
      sendDeleteUser: "user/deleteUser",
    }),
    async getUsersData() {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = setTimeout(async function () {
        await self.getUsersPaginated({
          ...self.filters,
          first_name: self.filters.first_name ?? undefined,
          last_name: self.filters.last_name ?? undefined,
          rut: self.filters.rut ?? undefined,
        });
        self.loading = false;
      }, self.debounceTime);
    },
    deleteUser(item) {
      this.$bvModal
        .msgBoxConfirm("Esta acción no se puede revertir", {
          title: "¿Estas seguro de eliminar este usuario?.",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-info-3",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.sendDeleteUser(item.id);
            if (resp.status == 204) {
              this.$bvToast.toast(`Usuario eliminado correctamente`, {
                title: `Excelente`,
                variant: "primary",
                solid: false,
              });
            }
            this.getUsersData();
          }
        })
        .catch((err) => {
          return err;
        });
    },
  },
  computed: {
    ...mapGetters({
      users: "user/users",
      usersPaginated: "user/usersPaginated",
      currentUser: "auth/currentUser",
    }),
    wrappedUsers() {
      return this.usersPaginated.data.map((user) => {
        return {
          ...user,
          full_name: user.first_name
            ? `${user.first_name} ${user.last_name}`
            : "Super Administrador",
          image: user.image
            ? user.image
            : require("@/assets/img/app/user/user-icon.png"),
        };
      });
    },
  },
  watch: {
    "filters.page": function (page) {
      this.getUsersData();
    },
  },
};
</script>
